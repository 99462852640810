import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currencies: [],
    date: "",
};

export const currencySlice = createSlice({
    name: "currencySlice",
    initialState,
    reducers: {
        setCurrencyData: (state, action) => {
            const { rates, ratesDate } = action.payload;
            state.currencies = rates;
            state.date = ratesDate;
        },
    },
});

export const { setCurrencyData } = currencySlice.actions;

export const selectCurrencies = (state) => state.currencyReducer.currencies;

export const selectDate = (state) => state.currencyReducer.date;

export default currencySlice.reducer;
