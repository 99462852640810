import { AbilityBuilder, PureAbility } from "@casl/ability";

const ACTIONS = {
  MANAGE: "manage",
  CREATE: "create",
  READ: "read",
  UPDATE: "update",
  DELETE: "delete",
};

function defineAbilitiesFor(rights, isGuest, restrictionsPlan, company) {
  const { can, cannot, build } = new AbilityBuilder(PureAbility);

  if (!isGuest) {
    // Permite administrarea completă ca punct de pornire.
    can(ACTIONS.MANAGE, "all");

    // Definim maparea între restricții și resursele afectate.
    const restrictionsMap = {
      modulETransport: "eTransport",
      modulContracteAbonamente: "contracte",
      trimitereXMLuriMultiple: "trimitereXMLuriMultiple",
      notificari: "notificari",
      modulNIR: "NIR",
      exportDateContabile: "exportDateContabile",
      facturaDetaliataXML: "facturaDetaliataXML",
      cheieTokenAcces: "cheieTokenAcces",
      cautareSelectieMultipla: "cautareSelectieMultipla",
      eTVA: "eTVA",
      integrariAPI: "integrariAPI",
      suportEmail: "suportEmail",
      suportTelefonic: "suportTelefonic",
    };

    // Iterăm peste restricțiile din `restrictionsPlan`.
    if (restrictionsPlan) {
      Object.entries(restrictionsMap).forEach(([key, resource]) => {
        if (restrictionsPlan[key] === false) {
          // Refuză accesul complet pentru resursele restricționate.
          cannot(ACTIONS.MANAGE, resource);
        }
      });
    }

    return build();
  }

  console.log("restrictionsPlan!xxx", restrictionsPlan);

  // Blocăm accesul la modulul eTransport dacă `modulETransport` este `false`
  //   if (restrictionsPlan && restrictionsPlan.modulETransport === false) {
  //     console.log("Acces interzis la eTransport! modulETransport este false.");
  //     cannot(ACTIONS.READ, "eTransport");
  //   }

  // Configurăm permisiunile într-un mod dinamic pe baza obiectului `rights`
  const permissions = [
    {
      condition: rights.create && rights.submit && rights.receive,
      canRead: [
        "editor",
        "drafts",
        "out",
        "in",
        "send",
        "view",
        "defs",
        "companies",
        "subscribe",
      ],
      cannotRead: ["serialRegister"],
    },
    {
      condition: rights.create && rights.submit && !rights.receive,
      canRead: [
        "editor",
        "drafts",
        "out",
        "send",
        "view",
        "defs",
        "companies",
        "subscribe",
      ],
      cannotRead: ["in", "serialRegister"],
    },
    {
      condition: rights.create && !rights.submit && rights.receive,
      canRead: [
        "editor",
        "drafts",
        "in",
        "out",
        "send",
        "view",
        "defs",
        "companies",
        "subscribe",
      ],
      cannotRead: ["serialRegister"],
    },
    {
      condition: rights.create && !rights.submit && !rights.receive,
      canRead: [
        "editor",
        "drafts",
        "out",
        "send",
        "view",
        "defs",
        "companies",
        "subscribe",
      ],
      cannotRead: ["in", "serialRegister"],
    },
    {
      condition: !rights.create && rights.submit && rights.receive,
      canRead: ["in", "out", "view", "companies", "subscribe"],
      cannotRead: ["editor", "drafts", "send", "defs", "serialRegister"],
    },
    {
      condition: !rights.create && rights.submit && !rights.receive,
      canRead: ["out", "view", "companies", "subscribe"],
      cannotRead: ["editor", "drafts", "in", "send", "defs", "serialRegister"],
    },
    {
      condition: !rights.create && !rights.submit && rights.receive,
      canRead: ["in", "view", "companies", "subscribe"],
      cannotRead: ["editor", "drafts", "out", "send", "defs", "serialRegister"],
    },
    {
      condition: !rights.create && !rights.submit && !rights.receive,
      canRead: ["companies", "subscribe"],
    },
    {
      condition: company === null,
      canRead: ["companies", "subscribe"],
    },
  ];

  // Aplicăm reguli dinamice pe baza condițiilor
  for (const permission of permissions) {
    if (permission.condition) {
      permission.canRead?.forEach((resource) => can(ACTIONS.READ, resource));
      permission.cannotRead?.forEach((resource) =>
        cannot(ACTIONS.READ, resource)
      );
      break; // Ieșim după prima condiție adevărată
    }
  }

  return build();
}

export default defineAbilitiesFor;
