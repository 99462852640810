import React from "react";
import NoPage from "pages/Error404";
import AccessDeniedPage from "pages/AccessDeniedPage";

import { Can } from "./AbilityContext";
import AccessDeniedSubscriptionPlan from "pages/AccessDeniedPageSubscriptionPlan";

const ProtectedRoute = ({ I, a, children }) => {
  return (
    <Can I={I} a={a} passThrough>
      {(allowed) => {
        console.log("Access allowed:", allowed); // Adaugă acest log pentru a verifica
        return allowed ? children : <AccessDeniedSubscriptionPlan />;
      }}
    </Can>
  );
};

export default ProtectedRoute;
