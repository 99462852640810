import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import defineAbilitiesFor from "./Abilty";

const initialState = {
  create: false,
  publish: false,
  receive: false,
  submit: false,
};

const isGuest = false;

const AbilityContext = createContext(
  defineAbilitiesFor(initialState, isGuest, {}, null) // restrictionsPlan={}, company=null
);

export const Can = createContextualCan(AbilityContext);

export default AbilityContext;
