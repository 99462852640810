import { Card, Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

const AccessDeniedSubscriptionPlan = () => {
  const navigate = useNavigate();

  return (
    <Card>
      <Result
        status="404"
        title="Acces restricționat din cauza abonamentului!"
        subTitle="Momentan, abonamentul dvs. nu include acces la această funcționalitate. Vă invităm să actualizați abonamentul pentru a beneficia de toate opțiunile."
        extra={[
          <Button
            type="primary"
            key="subscribe"
            onClick={() => navigate("/app/subscribe")}
          >
            Abonamente
          </Button>,
        ]}
      />
    </Card>
  );
};

export default AccessDeniedSubscriptionPlan;
